.Container{
    height: 100vh;
    display: flex;
}
.DashboardNav{
    position: relative;
    width: 20vw;
    height: 83vh;
    left: 5vh;
    top: 4vh;
    background: #FFFFFF;
    box-sizing: border-box;
}
.DesignBtn,.DashboardNav > a{
    border: 1px solid #4545C6;
    
    border-radius: 5px;
    padding: 1.5em 0em;
    text-align: center;
    margin: 3rem auto;
    list-style: none;
    background: #ffffff;
    text-decoration: none;
    font-family: 'poppins';
    font-size: 1rem;
    color: #4545C6;
    width: 88%;
    display: block;
    border-radius: 5px;
}
.DashboardNav > a:nth-child(1){
    margin-top:0px;
}
.DesignBtn {
    margin-bottom: 7%;
}

.MainDashboard{
    position: relative;
    width: 72vw;
    height: 83vh;
    left: 5vw;
    top: 4vh;
    background: #FFFFFF;
   
    box-sizing: border-box;
    box-shadow: 0px 4px 22px 10px rgba(0, 0, 0, 0.06);

    overflow: auto;
}
.ManageAccountHeader{
    display: flex;
    width: 90%;
    align-items: center;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1rem;
    margin: auto;
    margin-top: 3%;
    gap: 14%;
}

.ManageAccountHeader > button > img{
    width: 1em;
    height: 1em;
}
.ManageAccountHeader > span{
    font-weight: normal;
    font-size: 1.5rem;
}
.ManageAccountHeader > button{
    background: #4545C6;
    color: #FFFFFf;
    font-weight: 100;
    border-radius: 6px;
    border: none;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.3em;
    height: 3rem;
    justify-content: center;
    width: 25%;
}

.AccountsTable{
    width: 80%;
    margin: auto;
    margin-top: 2%;
}
.AccountsTable > tr{
    text-align: center;
    border-bottom: 2px solid #D3D3D3;
   
}
.AccountsTable >tr>th{
    padding: 0.5rem 0rem;
    font-size: 0.9rem;
    font-weight: normal;
    background: #F2F2F2;
    width: 25%;
}
.AccountsTable > tr > td{
    padding: 2em 0em;
    font-size: 0.9rem;
}
.AccountsTable > tr > td > button{
    padding: 0.5rem 1rem;
    border-radius: 0.5em;
    border: 0px;
    font-weight: 300;
    background: #E75151;
    color: white;
    width: 3.9rem;
    height: 1.9rem;
    font-size: 0.7rem;
}
.AccountsTable > tr > td:nth-child(3){
    gap: 1rem;
    width: 60%;
    margin: auto;
    display: flex;
    align-items: center;
    padding-top: 2.1rem;
}
.AccountsTable > tr > td > img{
    width: 1rem;
    height: 1rem;
}
.ActiveLink{
    background-color: #4545C6 !important;
    color: white !important;
    
}
.ActiveLinkDesigner{
    font-weight: bolder;
    color: #4545C6 !important;
}
/* Manage Social Accounts */
.ManageSocialAccountsHeader
{
    text-align: center;
    font-weight: bolder;
    font-size: 1.7rem;
    margin: 3rem 0px;
}
.UserHeader{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-bottom: 6%;
    align-items: flex-end;
}
.UserSelector{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap:1rem;
}
.UserSelector > span{
    font-size: 18px;

}
.UserSelectorSelect{
    background: #FFFFFF;
    border: 1px solid #5A54E5;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: normal;
    width: 150%;
    color: #5A54E5;

}
.UserSelector > select:active{
    border: 1px solid #5A54E5 !important;
}
.UserSelector > select >option{
    font-weight: normal;
    color: black;
    border: 3px solid black;
}
.CreatePostbtn{
    width: 195px;
    height: 51px;
    color: #FFFFFF;
    font-size: 0.9rem;
    background: #4545C6;
    border-radius: 5px;
    border: none;
}
.ViewPostSection{

    width: 60vw;
    margin: auto;
    margin-top: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
}
.ViewPostSection > img{
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px #4545C6;
}
/* Show Image */
.ShowImgSection{
    width: 90%;
    margin: auto;
    margin-top: 3%;
}
.ShowImgSection > button{
    padding: 0.5rem 1rem;
    background: #FFFFFF;
    border: none;
    border-radius: 10px;
    font-size: 1.3rem;
    
    margin-bottom:3%;
}
.ShowImgSection > button > img{
    height: 2rem;
    width: 2rem;
}
.ImgCommentSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem;
    margin-bottom: 4rem;

}
.ImgSection > img{
    height: 62vh;
    border-radius: 10px;
    
}
.ImgSection{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}
.CommentSection{
    
    max-height: 100vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
    border-radius: 5px; 
}
.CommentHeader {
    border-bottom: 1px solid #E0DFFF;
    margin: 3% 0px;
    padding: 3% 0px;
}
.CommentHeader > span{
    padding-left: 1rem;
    font-size: 0.8rem;
    color: #5A54E5;
}
.CommentHeader > h1{
    padding-left: 1rem;
    font-weight: bolder;
    font-size: 24px;
    margin: 0;
    padding-top: 0.5rem;

    
}
.Comment{
    padding: 1rem 2rem;
    display: grid;
    height: 66%;
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 1fr 25px;
    overflow: auto;
    
}
.UserCommentSection{
    position: relative;
    padding: 1rem 2rem;
    width: 100%;
    display: flex;
    gap: 1rem;
    /* grid-template-columns: 1fr 6fr 1fr;
    grid-template-rows: 1fr; */
}
.UserCommentSection > button{
    color: white;
    background-color:#4545C6 ;
    font-size: 1rem;
    border: none;
    width: 20%;
    border-radius: 10px;
}
.Comment > img{
    width: 50px;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 500px;
}
.UserPic{
    width: 50px;
    height: 50px;
    border: 5px solid #cccccc;
    border-radius: 50%;
}
.Comment > p{
    border: none;
   
    margin-left: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 14rem;
    padding: 1rem 1rem 1rem 1rem;
    background: #E9E8FF;
    border-radius: 3px;
}
.UserComment{
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-left: 0rem;
    width: 87%;
    padding: 0.7rem;
    padding-bottom: 2rem;
    font-size: 0.8rem;
    
    text-align: left;
    background: #E9E8FF;
}
.UserCommentEmoji{
    display: flex;
    gap: 0.4rem;
    position: absolute;
    bottom: 22%;
    right: 28%;
    background: none;
    height: 16%;
}
.Comment > span{
    grid-column: 2/3;
    grid-row: 2/3;
    text-align: left;
    margin-left: 1rem;
}
.TimeLikeReply{
    grid-column: 2/3;
    display: flex;
    
}
.Time{
    margin-left: 2rem;
    font-size: 0.8rem;
}
.LikeReply{
    margin-left: 4rem;
    font-size: 0.8rem;
}
.CreatePostSection{
    /* display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 100px 4fr; */
}
.CreatePostSection > button{
        padding: 0.5rem 1rem;
    background: #FFFFFF;
    border:none;    
    font-size: 1.3rem;
    margin: 2rem 2.5rem 3rem;
    
}
.CreatePostSection > button > img{
    height:2rem;
}
.CreatePostHeadings >p{
    margin-top: 2rem;
}

.PostSection{
    grid-column: 1/4;
    display: flex;
    width: 60%;
    margin: auto;
    margin-top: 1%;
}
.PostSection > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3rem;
}
#CreatePost{
    margin-top: 0%;
    margin-left: 0%;
    width: 42vw;
    grid-column: 1/3;
    /* height: 15vh; */
    border-radius: 10px;
    padding: 1vh 1rem 19vh;
    border: 1px solid #5A54E5;
    background: #EFEEFF;
}
.PostBtnsection{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0px;
    width: 100%;
}
.PhotoVideo{
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.07);
    border: 1px solid #5A54E5;
    padding: 0.3rem;
    width: 12em;
    display: flex;
    font-weight: bolder;
    gap: 1.4rem;
    align-items: center;
}
.PostBtnsection > div{
    width: 50%;
    display: flex;
    gap: 1rem;
    justify-content: end;
}
.Postbtn,.CancelBtn{
    border: 1px solid #5A54E5;
    box-sizing: border-box;
    border-radius: 5px;
    width: 40%;
}
.Postbtn {
    color:white;
    background: #4545C6;
    border-radius: 5px;
    border:none;
}
.CancelBtn{
    background-color: white;
    color:#4545c6;
}
.PhotoVideo > img{
    height: 2rem;
    width: 2rem;
}
/* Add user section */

.AddNewAccountContainer{
    width: 80%;
    margin: 5% auto;
}
.AddNewAccountBanner {
    display: grid;
    grid-template-columns: 1fr 3fr;

}
.AddNewAccountBanner > button{
    padding: 0.5rem 0rem;
    background: #FFFFFF;
    border: none;
    border-radius: 10px;
    font-size: 1.3rem;
    width: 0%;
    outline: none;
}
.AddNewAccountBanner > button > img{
    height: 2rem;
    width: 2rem;
}
.AddNewAccountBanner > h1{
    text-align: start;
    
}
.AddNewAccountHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.PlusAddNewAccount{
    gap: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0rem;
}
.PlusAddNewAccount > img{
    width: 2rem;
}
.AddNewAccountHeader > span{
    font-size: 13px;
    width: 73%;
    text-align: center;
}
.AccountTypes{
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 14% auto;
}
.AccountTypes > button{
    margin: 5% auto;
    width: 100%;
    padding: 2rem 1rem;
    font-weight: normal;
    background: #FFFFFF;
    border: 1px solid #4545C6;
    color: #4545C6;
    border-radius: 10px;
}
.AccountTypes > button > img{
    width: 1rem;
}
.AccountTypes > button:hover{
    background-color:#4545C6 ;
    color:white;
}
/* Design Editor */
.DesignMaker{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;
    margin-left: auto;
    font-weight: bolder;
    align-items: center;
    display: none;

}
.DesignMaker > a{
    color: black;  
    text-align: start;
}
.DesignEditorHeader{
    text-align: center;
    margin-top: 5vh;
    font-size: 2rem;
    font-weight: normal;
}
.DesignerCreateNew{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4rem auto;
}
.DesignerCreateNew > span{
    font-size: 1.2rem;
}
.DesignerCreateNew > button{
    background: #4545C6;
    color: #FFFFFf;
    padding: 0.6rem 1rem;
    font-weight: bolder;
    border-radius: 6px;
}
.DesignerTemplates{
    border: 1px solid;
    margin-top: 7rem;
}
.DesignerTemplates > img{
    width: calc(11vh + 10vw);
    height: calc(10vh + 11vw);
    margin: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    border: 1px solid #DDDCE0;
}
.SelectTemplate{
    display: flex;
    flex-direction: column;
}
.SelectTemplate > span:nth-child(1){
    font-size: 22px;
}
.SelectTemplate > span:nth-child(2){
    font-size: 12px;
    color: #5A54E5;

}
.DesignEditorCanvasArea{
    position: relative;
    display: flex;
    width: 90vw;
    height: 150vh;
    flex-direction: column;
    left: 4vw;
    align-items: center;
   
    top: 7vh;
    background: #FFFFFF;
    margin-bottom: 17vh;
    
    box-shadow: 0px 4px 14px 6px rgba(0, 0, 0, 0.06);

}
.DesignEditorBanner{
    
    width: 90%;
  
    margin: 10% auto 2%;
    display: flex;
    justify-content: space-between;
}
.DesignEditorBanner > button{
    
    width: 10%;
    
    padding: 0.8rem 1rem;
    font-weight: bolder;
    background: white;
    border: none;
    border-radius: 10px;
    outline: none;
}
.DesignEditorBanner > button > img{
    height: 2rem;
    width: 2rem;
}
.DesignEditorBanner > div{
    
    width: 50%;
    display: flex;
    justify-content: space-around;
    
}
.DesignEditorBanner > div > button{
    width: 60%;
    margin: 0px 10px;
    padding: 0.8rem 0rem;
    font-weight: normal;
    background: #4545C6;
    border: 3px solid #4545C6;
    font-size: 0.9rem;
    border-radius: 5px;
    color: white;
}
.DesignEditorBanner > div > button:nth-child(2){
    color: #4545C6;
    border: 1px solid #4545C6;
    background: white;
    padding: auto;
    display: flex;
    justify-content: center;
    gap: 0.4em;
    align-items: center;
}
.DesignEditorBanner > div > button > img{
    width: 2em;
    height: 1.5em;
}
.Designer{
    margin-top: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Designer > canvas{
    border: 1px solid;
    height: 100%;
    background-color: #C4C4C4;
    width: 70%;
    margin-right: 5%;
}
.DesignerButtons{
    border: 1px solid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    margin-left: 7%;
    width: 15%;
    background: #FFFFFF;
    border: 1px solid #C3C5F5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.DesignerButtons > button{
    height: 3rem;
    width: 4rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: #4545C6;
    color: #FFFFFF;
    font-size: 1rem;
    border-radius: 4px;
    border: 3px solid #4545C6;
}
.DesignerButtons > button:nth-child(1){
    padding-top: 0.7rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(2){
    padding-top: 0.7rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(3){
    padding:0.2rem;
}
.DesignerButtons > button:nth-child(4){
    padding-top: 0.4rem;
    padding-right: 0.4rem;
}
.DesignerButtons > button:nth-child(5){
    padding-top: 0.3rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(6){
    padding-top: 0.5rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(7){
    padding-top: 0.5rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(8){
    padding-top: 0.4rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(9){
    padding-top: 0.7rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(10){
    padding-top: 0rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(11){
    padding-top: 0rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(12){
    padding-top: 0rem;
    padding-right: 0.3rem;
}
.DesignerButtons > button:nth-child(13){
    padding-top: 0.4rem;
    padding-right: 0.2rem;
}
.DesignerButtons > button:nth-child(14){
    padding-top: 0.8rem;
}
.SavedDesignsView{
    text-align: center;
    margin: 0px auto;
    margin-top: 18%;
    font-size: 1rem;

}
.SavedDesignView{
    width: 100%;
    height: 100%;
}
.ShowSavedDesigns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    overflow: auto;
}
.ShowSavedDesigns > div{
    margin: auto;
}
.ShowSavedDesigns > div:nth-child(1){
    width: 45%;
   
}

.ShowSavedDesigns > div > img {
    box-shadow: 0px 4px 20px #575AC2;
    width: 100%;
    height: 555px;
}
.ShowSavedDesigns > div:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 40%;
   
}
.ShowSavedDesigns > div:nth-child(2) > button{
    
    width: 55%;
    padding: 1.5em 1.5em;
    background: #4545C6;
    color:white;
    border:none;
    border-radius: 5px;
}
.ShowSavedDesigns > div:nth-child(2) > button:nth-child(3){
    
    
    
    background: white;
    color:#4545C6;
    border:1px solid #4545C6;
} 
@media only screen and (max-width: 1024px){
    .MainDashboard{
        position: relative;
        width: 65%;
        height: 80%;
        left: 11%;
        top: 4%;
       
    }
    .Container{
        height: 80vh;
        display: flex;
    }
    .DashboardNav{
        position: relative;
        width: 20vw;
        height: 80%;
        left: 7%;
        top: 4%;
       
    }
    .DesignBtn,.DashboardNav > a{
      
        width: 14vw;
        padding: 1em 0em 2.5em;
        text-align: center;
        margin: 3rem auto;
        list-style: none;
       
        border-radius: 5px;
        text-decoration: none;
        font-family: 'poppins';
        font-size: 0.8rem;
       
        width: 95%;
        display: block;
        height: 6%;
    }
    .DesignBtn {
        margin-bottom: 7%;
    }
    
    .ManageAccountHeader{
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-top: 3%;
    }
    .ManageAccountHeader > span{
        font-weight: normal;
        font-size: 1rem;
    }
    .ManageAccountHeader > button{
        background: #4545C6;
        color: #FFFFFf;
        font-weight: normal;
        border-radius: 6px;
        font-size: 0.7rem;
        height: 2.1rem;
        width: 30%;
    }
    .AccountsTable{
        width: 80%;
        margin: auto;
        margin-top: 2%;
    }
    .AccountsTable > tr{
        text-align: center;
    }
    .AccountsTable >tr>th{
        padding: 0.5rem 0rem;
        font-size: 0.7rem;    
    }
    .AccountsTable > tr > td{
        padding: 1rem 0rem;
        font-size: 0.7rem;
    
    }
    .AccountsTable > tr > td:nth-child(3){
        padding-top: 1.5rem;
        gap: 0.5rem;
    }
    .AccountsTable > tr > td > button{
        padding: 0.5em 1em;
        border-radius: 0.5em;
        font-size: 0.567rem;
        border: 0px;
        background: #E75151;
        color: white;
        width: 3.5rem;
        height: 1.5rem;
    }
    
    
    /* Manage Social Accounts */
    .ManageSocialAccountsHeader
    {
        text-align: center;
        font-weight: bolder;
        font-size: 1.5rem;
        margin: 3rem 0px;
    }
    .UserHeader{
        display: flex;
        justify-content: space-between;
        width: 90%;
        height: 5rem;
        align-items: baseline;
        margin: auto;
        margin-bottom: 6%;
        align-items: flex-end;
    }
    .UserSelector{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        height: 100%;
    }
    .UserSelector > span{
        font-size: 1rem;
    }
    .UserSelector > select{
        
        padding: 0.2eem 0.em;
        font-weight: normal;
        width: 100%;
    
    }
    .UserSelector > select >option{
        font-size: 0.6rem;    
    }
    .CreatePostbtn{
        width: 30%;
        height: 50%;
        color: #FFFFFF;
        font-size: 1rem;
        
    }
    .ViewPostSection{
    
        width: 60vw;
        margin: auto;
        margin-top: 10%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1rem;
        grid-column-gap: 1rem;
    }
    .ViewPostSection > img{
        width: 250px;
        height: 250px;
        border: 1px solid black;
    }
    /* Show Image */
    .ShowImgSection{
        width: 90%;
        margin: auto;
        margin-top: 3%;
    }
    .ShowImgSection > button{
        padding: 0.5rem 1rem;
        background: #FFFFFF;
        border:none;
        border-radius: 10px;
        font-size: 1rem;
        width: 20%;
        margin-bottom:5%;
        margin-top: 3%;
    }
    .ShowImgSection > button > img{
        height: 2rem;
    }
    .ImgCommentSection{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6rem;
    
    }
    .ImgSection > img{
        height: 50%;
        width: 130%;
       
    }
    .ImgSection{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
    }
    .CommentSection{
       
        max-height: 100%;
        width: 95%;
        margin: 0px auto;
        
    }
    .CommentHeader > span{
        font-size: 0.6rem;
    }
    .CommentHeader > h1{
       
        font-weight: bolder;
        font-size: 1.2rem;
        padding-top: 1.5rem;
       
       
    }
    .Comment{
        padding: 1rem 2rem;
        display: grid;
        height: 67%;
        grid-template-columns: 1fr 6fr;
        grid-template-rows: 1fr 25px;
        overflow: auto;
        
    }
    .UserCommentSection{
        padding: 1rem 1rem;
        width: 100%;
        display: flex;
        /* grid-template-columns: 1fr 6fr 1fr;
        grid-template-rows: 1fr; */
    }
    .UserCommentSection > button{
        padding: 0.5em 0em;
        font-size: 1rem;
        width: 30%;
    }
    .Comment > img{
        width: 50px;
        height: 50px;
        border: 1px solid #cccccc;
        border-radius: 50%;
    }
    .UserPic{
        width: 50px;
        height: 50px;
        border: 5px solid #cccccc;
        border-radius: 50%;
    }
    .Comment > p{
        border: none;
        border-radius: 10px;
        margin-left: 1rem;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 14rem;
        padding: 1rem 1rem 1rem 1rem;
    }
    .UserComment{
        
        border-radius: 10px;
        margin-left: 1rem;
        width: 100%;
        
        font-size: 0.7rem;
        text-align: left;
    }
    .UserCommentEmoji{
        display: flex;
        gap: 0.4rem;
        position: absolute;
        bottom: 22%;
        right: 31%;
        background: none;
        height: 16%;
    }
    .Comment > span{
        grid-column: 2/3;
        grid-row: 2/3;
        text-align: left;
        margin-left: 1rem;
    }
    .TimeLikeReply{
        grid-column: 2/3;
        display: flex;
        
    }
    .Time{
        margin-left: 2rem;
        font-size: 0.8rem;
    }
    .LikeReply{
        margin-left: 4rem;
        font-size: 0.8rem;
        width: 8vh;
    }
    .CreatePostSection{
        /* display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 100px 4fr; */
    }
    .CreatePostSection > button{
        padding: 0.5rem 1rem;
        background: #FFFFFF;
       
        border-radius: 10px;
        font-size: 1rem;   
    }
    .CreatePostSection > button > img{
        height: 1.4rem;
        width: 1.6rem;
    }
    .PostSection{
        grid-column: 1/4;
        display: flex;
        width: 60%;
        margin: auto;
        margin-top: 4%;
        margin-bottom: 14%;
    }
    
    #CreatePost{
        margin-top: 6%;
        margin-left: 0%;
        width: 35vw;
        grid-column: 1/3;
        /* height: 15vh; */
        border-radius: 10px;
        padding: 1vh 1rem 9vh;
        
    }
    .PostBtnsection{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0px;
        width: 100%;
    }
    .PhotoVideo{
        background: #FFFFFF;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.07);
        border: 1px solid #5A54E5;
        padding: 0.8rem;
        width: 50%;
        display: flex;
        font-size: 0.7rem;
        gap: 0.7rem;
        align-items: center;
    }
    .PostBtnsection > div{
        width: 50%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
    }
    .Postbtn,.CancelBtn{
        border: 1px solid #5A54E5;
        height: 90%;
        border-radius: 5px;
        font-size: 0.8rem;
        width: 40%;
    }
    .Postbtn {
        color:white;
        background: #4545C6;
        border-radius: 5px;
        border:none;
    }
    .CancelBtn{
        background-color: white;
        color:#4545c6;
    }
    .PhotoVideo > img{
        height: 1.5rem;
        width: 1.5rem;
    }
    /* Add user section */
    
    .AddNewAccountContainer{
        width: 90%;
        margin: 5% auto;
    }
    .AddNewAccountBanner {
        display: grid;
        grid-template-columns: 0.7fr 3fr;
    
    }
    .AddNewAccountBanner > button{
        padding: 0.3rem 0.6rem;
       
        border-radius: 10px;
        font-size: 0.7rem;
        width: 55%;
    }
    .AddNewAccountBanner > button > img{
        height: 1.5rem;
        width: 1.5rem;
    }
    .AddNewAccountHeader  > h1{
        text-align: start;
        font-size: 2rem;
    }
    .AddNewAccountHeader > span{
        font-size: 12px;
        width: 74%;
        text-align: center;
    }
    .PlusAddNewAccount > h1{
        font-size: 2rem;
    }
    .PlusAddNewAccount > img {
        width: 1.5rem;
    }
    .AccountTypes{
        display: flex;
        flex-direction: column;
        width: 45%;
        margin: 26% auto;
    }
    .AccountTypes > button{
        margin: 5% auto;
        width: 100%;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 1rem;
        font-weight: normal;
      
        border-radius: 10px;
    }
    .AccountTypes > button > svg{
        height: 1rem;
        width: 1rem;
    }
    /* Design Editor */
    .DesignMaker{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
        margin-left: auto;
        font-weight: bolder;
        align-items: center;
        margin-left: auto;
        font-size: 0.8rem;
        display: none;
    
    }
    .DesignMaker > a{
        color: black;  
        text-align: start;
    }
    .DesignEditorHeader{
        text-align: center;
        margin-top: 5vh;
        font-size: 2rem;
        font-weight: normal;
    }
    .DesignerCreateNew{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5rem auto 2rem;
    }
    .DesignerCreateNew > span{
        font-size: 1.2rem;
    }
    .DesignerCreateNew > button{
        background: #4545C6;
        color: #FFFFFf;
        padding: 0.6rem 1rem;
        font-weight: bolder;
        border-radius: 6px;
        border:none;
        font-size: 0.7rem;
    }
    .DesignerTemplates{
        border: 1px solid;
        margin-top: 7rem;
    }
    .DesignerTemplates > img{
        width: calc(9vh + 9vw);
        height: calc(9vh + 9vw);
    }
    .DesignEditorCanvasArea{
        position: relative;
        display: flex;
        width: 90vw;
        height: 150vh;
        flex-direction: column;
        left: 4vw;
        align-items: center;
        
        top: 7vh;
        background: #FFFFFF;
        margin-bottom: 17vh;
        box-shadow: 0px 4px 14px 6px rgb(0 0 0 / 6%);
    
    }
    .DesignEditorBanner{
        
        width: 90%;
      
        margin: 10% auto 2%;
        display: flex;
        justify-content: space-between;
    }
    .DesignEditorBanner > button{
        
        width: 10%;
        
       
        font-weight: bolder;
        background: white;
        border-radius: 10px;
    }
  
    .DesignEditorBanner > div{
        
        width: 60%;
        display: flex;
        justify-content: space-around;
        
    }
    .DesignEditorBanner > div > button{
        width: 40%;
        margin: 0px 10px;
        padding: 0.8rem 0rem;
        font-weight: normal;
        background: #4545C6;
        border: 3px solid #4545C6;
        font-size: 0.8rem;
        border-radius: 5px;
        color: white;
    }
    .Designer{
        margin-top: 5%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .Designer > canvas{
        border: 1px solid;
        height: 100%;
        background-color: #C4C4C4;
        width: 60%;
        margin-right: 5%;
    }
    .DesignerButtons{
        border: 1px solid #C3C5F5;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        margin-left: 7%;
        width: 22%;
    }
    .DesignerButtons > button{
        height: 4rem;
        width: 4rem;
        margin-left: 10%;
        margin-top: 2rem;
        background: #4545C6;
        color: #FFFFFF;
        font-size: 1rem;
        border-radius: 4px;
        border: 3px solid #4545C6;
    }
    .DesignerButtons > button:nth-child(1){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(2){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(3){
        padding:0.2rem;
    }
    .DesignerButtons > button:nth-child(4){
        padding-top: 0.4rem;
        padding-right: 0.4rem;
    }
    .DesignerButtons > button:nth-child(5){
        padding-top: 0.3rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(6){
        padding-top: 0.5rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(7){
        padding-top: 0.5rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(8){
        padding-top: 0.4rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(9){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(10){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(11){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(12){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(13){
        padding-top: 0.4rem;
        padding-right: 0.2rem;
    }
    .DesignerButtons > button:nth-child(14){
        padding-top: 0.8rem;
    }
    .SavedDesignsView > h1{
        font-size: 1.6rem;
    }

    .ShowSavedDesigns > div > img {
        box-shadow: 0px 4px 20px #575AC2;
        width: 100%;
        height: 555px;
    }
    .ShowSavedDesigns > div:nth-child(1){
        width: 55%;

    }
    .ShowSavedDesigns > div:nth-child(2){
        width: 35%;

    }
    .ShowSavedDesigns > div:nth-child(2) > button{
        width: 65%;
        padding: 1em 1.5em;
    }
    .ShowSavedDesigns > div:nth-child(2) > button:nth-child(3){
        
    }
    .makeStyles-select-1{
        padding: 0.3rem 0.1rem !important;
    }
    .MuiSelect-select.MuiSelect-select{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 4px !important;
    }
}
@media only screen and (max-width: 768px){
    .MainDashboard{
        position: relative;
        width: 65%;
        height: 80%;
        left: 8%;
        top: 4%;
        background: #FFFFFF;
       
    }
    .Container{
        height: 80vh;
        display: flex;
    }
    .DashboardNav{
        position: relative;
        width: 25vw;
        height: 80%;
        left: 5%;
        top: 4%;
        
    }
    .DesignBtn,.DashboardNav > a{
     
        padding: 0px;
        text-align: center;
        margin: 3rem auto;
        list-style: none;
        background: #ffffff;
        border-radius: 5px;
        text-decoration: none;
        font-family: 'poppins';
        font-size: 0.599rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 89%;
        
        height: 10%;

    }
    .DesignBtn {
        margin-bottom: 7%;
    }
    
    .ManageAccountHeader{
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem 0.3rem;
        margin: auto;
        margin-top: 3%;
    }
    .ManageAccountHeader > span{
        font-weight: normal;
        font-size: 0.78rem;
    }
    .ManageAccountHeader > button{
        background: #4545C6;
        color: #FFFFFf;
        padding: 1em 0em;
        font-weight: normal;
        border-radius: 3px;
        width: 28%;
        height: 10%;
        font-size: 0.456rem;
    }
    .AccountsTable{
        width: 90%;
        margin: auto;
        margin-top: 2%;
    }
    .AccountsTable > tr{
        text-align: center;
    }
    .AccountsTable >tr>th{
        padding: 0.5rem 0rem;
        font-size: 0.5rem;
        font-weight: normal;
        
        width: 25%;
    }
    .AccountsTable > tr > td{
        padding: 1rem 0rem;
        font-size: 0.5rem;
    }
    .AccountsTable > tr > td > img {
        width: 0.5rem;
        height: 0.5rem;
    }
    .AccountsTable > tr > td:nth-child(3) {
        padding-top: 1.4rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .AccountsTable > tr > td > button{
        padding: 0rem 0rem;
        border-radius: 0.5em;
        font-size: 0.41rem;
        border: 0px;
        background: #E75151;
        color: white;
        width: 2.6rem;
        height: 1.2rem;
    }
    .ActiveLink{
        background-color: #4545C6 !important;
        color: white !important;
        
    }
    .ActiveLinkDesigner{
        font-weight: bolder;
        color:#4545C6
    }
    /* Manage Social Accounts */
    .ManageSocialAccountsHeader
    {
        text-align: center;
        font-weight: bolder;
        font-size: 1.7rem;
        margin: 3rem 0px;
    }
    .UserHeader{
        display: flex;
        justify-content: space-between;
        width: 90%;
        height: 13vh;
        margin: auto;
        margin-bottom: 6%;
        align-items: flex-end;
    }
    .UserSelector{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    .UserSelector > span{
        font-size: 1rem;
    }
    .UserSelector > select{
        
        padding: 0.2rem 0.2rem;
        font-weight: normal;
        width: 100%;
        font-size: 0.8rem;
    }
    .UserSelector > select >option{
        font-weight: normal;
        border: 3px solid black;
        font-size: 0.6rem;
    }
    .CreatePostbtn{
        width: 25%;
        height: 46%;
        color: #FFFFFF;
        font-size: 0.8rem;
        background: #4545C6;
    }
    .ViewPostSection{
    
        width: 60vw;
        margin: auto;
        margin-top: 10%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1rem;
        grid-column-gap: 1rem;
    }
    .ViewPostSection > img{
        width: 200px;
        height: 200px;
        border: 1px solid black;
    }
    /* Show Image */
    .ShowImgSection{
        width: 90%;
        margin: auto;
        margin-top: 3%;
    }
    .ShowImgSection > button{
        padding: 0.5rem 0rem;
        background: #FFFFFF;
        border: none;
        border-radius: 10px;
        font-size: 0.8rem;
        width: 20%;
        margin-bottom:5%;
        margin-top: 3%;
    }
    .ImgCommentSection{
        display: grid;
        grid-template-columns: 2fr;
        grid-gap: 1rem;
    
    }
    .ImgSection > img{
        height: 100%;
        width: 100%;
        margin: 10% 0px;
       
    }
    .ImgSection{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
    }
    .CommentSection{

        
        border-radius: 20px;
        max-height: 86%;
        width: 90%;
        margin: 20% auto;
        
    }
    .CommentHeader  > h1{
        
        font-weight: bolder;
        font-size: 1.5rem;
        padding-top: 1.5rem;
    }
    .Comment{
        padding: 1rem 2rem;
        display: grid;
        height: 67%;
        grid-template-columns: 1fr 6fr;
        grid-template-rows: 1fr 25px;
        overflow: auto;
        
    }
    .UserCommentSection{
        padding: 1rem 1rem;
        width: 100%;
        display: flex;
        /* grid-template-columns: 1fr 6fr 1fr;
        grid-template-rows: 1fr; */
    }
    .UserCommentSection > button{
        
        font-size: 0.9rem;
        
    }
    .Comment > img{
        width: 50px;
        height: 50px;
        border: 1px solid #cccccc;
        border-radius: 50%;
    }
    .UserPic{
        width: 50px;
        height: 50px;
        border: 5px solid #cccccc;
        border-radius: 50%;
    }
    .Comment > p{
        border: none;
        border-radius: 10px;
        margin-left: 1rem;
       
        width: fit-content;
        max-width: 14rem;
        padding: 1rem 1rem 1rem 1rem;
    }
    .UserComment{
        border: none;
        border-radius: 10px;
        margin-left: 1rem;
        width: 80%;
        
        font-size: 1rem;
        text-align: left;
    }
    .UserCommentEmoji{
        display: flex;
        gap: 0.4rem;
        position: absolute;
        bottom: 22%;
        right: 32%;
        background: none;
        height: 16%;
    }
    .Comment > span{
        grid-column: 2/3;
        grid-row: 2/3;
        text-align: left;
        margin-left: 1rem;
    }
    .TimeLikeReply{
        grid-column: 2/3;
        display: flex;
        margin: 0rem 0px 1rem;
        color: #565656;
    }
    .Time{
        margin-left: 2rem;
        font-size: 0.8rem;
    }
    .LikeReply{
        margin-left: 4rem;
        font-size: 0.8rem;
        width: 8vh;
    }
    .CreatePostSection{
        /* display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 100px 4fr; */
    }
    .CreatePostSection > button{
        padding: 0.5rem 0.7rem;
        background: #FFFFFF;
        border-radius: 10px;
        font-size: 0.8rem;
        
        
    }
    .PostSection{
        grid-column: 1/4;
        display: flex;
        width: 60%;
        margin: auto;
        margin-top: 3%;
        margin-left: 10%;
    }
    #CreatePost{
        margin-top: 6%;
        margin-left: 0%;
        width: 40vw;
        grid-column: 1/3;
        /* height: 15vh; */
        border-radius: 10px;
        padding: 1vh 1rem 6vh;
        border: 1px solid #5A54E5;
        background: #EFEEFF;
    }
    .PostBtnsection{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
        width: 100%;
    }
    .PhotoVideo{
        background: #FFFFFF;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.07);
        border: 1px solid #5A54E5;
        padding: 0.8rem;
        width: 50%;
        display: flex;
        gap: 1rem;
        color: black;
        font-weight: bolder;
        align-items: center;
    }
    .PostBtnsection > div{
        width: 60%;
        display: flex;
        gap: 0.4rem;
        justify-content: end;
    }
    .Postbtn,.CancelBtn{
        border: 1px solid #5A54E5;
        box-sizing: border-box;
        border-radius: 5px;
        width: 40%;
        font-size: 0.7rem;
    }
    .Postbtn {
        color:white;
        background: #4545C6;
        border-radius: 5px;
        border:none;
    }
    .CancelBtn{
        background-color: white;
        color:#4545c6;
    }
    .PhotoVideo > img{
        height: 1.3rem;
        width: 1.3rem;
    }
    
    /* Add user section */
    
    .AddNewAccountContainer{
        width: 90%;
        margin: 5% auto;
    }
    .AddNewAccountBanner {
        display: grid;
        grid-template-columns: 1fr 4fr;
    
    }
    .AddNewAccountBanner > button{
        padding: 0rem 0rem;
        background: #FFFFFF;
        
        width: 56%;
        height: 57%;
        
    }
    .AddNewAccountHeader > span {
        font-size: 12px;
        width: 81%;
        text-align: center;
    }
    .PlusAddNewAccount{
        margin-bottom: 1rem;
    }
    .PlusAddNewAccount > h1 {
        font-size: 1.5rem;
        margin: 0;
    }
    .AddNewAccountHeader > h1{
        text-align: start;
        font-size: 2rem;
        margin-top: 27px;
    }
    .AccountTypes{
        display: flex;
        flex-direction: column;
        width: 60%;
        margin: 26% auto;
    }
    .AccountTypes > button{
        margin: 5% auto;
        width: 100%;
        
        padding: 0.8rem 1rem;
        font-weight: normal;
    }
    /* Design Editor */
    .DesignMaker{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
        margin-left: auto;  
        font-weight: bolder;
        align-items: center;    
        margin:auto;
        font-size: 0.7rem;
        display: none;
    
    }
    .DesignMaker > a{
        color: black;  
        text-align: start;
    }
    .DesignEditorHeader{
        text-align: center;
        margin-top: 5vh;
        font-size: 2rem;
        font-weight: normal;
    }
    .DesignerCreateNew{
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5rem auto 2rem;
    }
    .DesignerCreateNew > span{
        font-size: 1.1rem;
    }
    .DesignerCreateNew > button{
        background: #4545C6;
        color: #FFFFFf;
        padding: 0.6rem 0.8rem;
        font-weight: bolder;
        border-radius: 6px;
        font-size: 0.8rem;
    }
    .DesignerTemplates{
        border: 1px solid;
        margin-top: 4rem;
    }
    .DesignerTemplates > img{
        width: calc(9vh + 9vw);
        height: calc(9vh + 9vw);
    }
    .DesignEditorCanvasArea{
        position: relative;
        display: flex;
        width: 90vw;
        height: 150vh;
        flex-direction: column;
        left: 4vw;
        align-items: center;
        
        top: 7vh;
        background: #FFFFFF;
        margin-bottom: 17vh;
        box-shadow: 0px 4px 14px 6px rgb(0 0 0 / 6%);
    
    }
    .DesignEditorBanner{
        
        width: 90%;
      
        margin: 10% auto 2%;
        display: flex;
        justify-content: space-between;
    }
    .DesignEditorBanner > button{
        
        width: 15%;
        
        padding: 0.4rem 0rem;
    }
    .DesignEditorBanner > div{
        
        width: 80%;
        display: flex;
        justify-content: space-around;
        
    }
    .DesignEditorBanner > div > button{
        width: 30%;
        margin: 0px 10px;
        padding: 0.8rem 0rem;
        font-weight: normal;
        background: #4545C6;
        border: 3px solid #4545C6;
        border-radius: 5px;
        color: white;
        font-size: 0.8rem;
    }
    
    .Designer{
        margin-top: 5%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .Designer > canvas{
        border: 1px solid;
        height: 100%;
        background-color: #C4C4C4;
        width: 60%;
        margin-right: 5%;
    }
    .DesignerButtons{
        border: 1px solid #C3C5F5;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        margin-left: 7%;
        width: 22%;
    }
    .DesignerButtons > button{
        height: 3rem;
        width: 3rem;
        padding-right: 0px;
        margin-left: 10%;
        margin-top: 2rem;
        background: #4545C6;
        color: #FFFFFF;
        font-size: 1rem;
        border-radius: 4px;
        border: 3px solid #4545C6;
    }
    .DesignerButtons > button:nth-child(1){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(2){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(3){
        padding:0.2rem;
    }
    .DesignerButtons > button:nth-child(4){
        padding-top: 0.4rem;
        padding-right: 0.4rem;
    }
    .DesignerButtons > button:nth-child(5){
        padding-top: 0.3rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(6){
        padding-top: 0.5rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(7){
        padding-top: 0.5rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(8){
        padding-top: 0.4rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(9){
        padding-top: 0.7rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(10){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(11){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(12){
        padding-top: 0rem;
        padding-right: 0.3rem;
    }
    .DesignerButtons > button:nth-child(13){
        padding-top: 0.4rem;
        padding-right: 0.2rem;
    }
    .DesignerButtons > button:nth-child(14){
        padding-top: 0.8rem;
    }
    svg {
        width: 120%;
        height: 100%;
    }
    .ShowSavedDesigns {
        flex-direction: column;
        height: 150%;
    }
    .ShowSavedDesigns > div > img {
        box-shadow: 0px 4px 20px #575AC2;
        width: 100%;
        height: 555px;
    }
    .ShowSavedDesigns > div:nth-child(1){
        width: 90%;
        margin-top: 5rem;

    }
    .ShowSavedDesigns > div:nth-child(2){
        width: 90%;
        flex-direction: row;
        gap:1rem;
        align-items: baseline;
        height: 100%;

    }
    .ShowSavedDesigns > div:nth-child(2) > button{
        margin-top: 5rem;
        width: 100%;
    }
    .ShowSavedDesigns > div:nth-child(2) > button:nth-child(3){
        
    }
    
}
@media only screen and (max-width: 540px){
    .MainDashboard{
        position: relative;
        width: 93%;
        height: 90%;
        left: 5%;
        top: 3vh;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 0px 4px 22px 10px rgb(0 0 0 / 25%);
    }
    .Container{
        height: 80vh;
        display: grid;
    }
    .DashboardNav{
        position: relative;
        width: 93%;
        height: 15vh;
        display: flex;
        justify-content: space-evenly;
        top: 10%;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 0px 4px 22px 10px rgb(0 0 0 / 25%);
    }
    .DesignBtn,.DashboardNav > a{
       
        padding: 0;
        text-align: center;
        list-style: none;
        background: #ffffff;
        border-radius: 5px;
        text-decoration: none;
        font-family: 'poppins';
        font-size: 0.56rem;
        width: 28vw;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        margin: auto 0px;
    }
    .DashboardNav > a:nth-child(1){
        margin: auto 0px;
    }
    .DesignBtn {
        padding: 0px;
    }
    
    .ManageAccountHeader{
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CACACA;
        padding: 0.7rem 1rem;
        margin: auto;
        margin-top: 3%;
    }
    .ManageAccountHeader > span{
        font-weight: normal;
        font-size: 1.1rem;
        margin: 16px auto 2rem;
        order: 1;
        
    }
    .ManageAccountHeader > button{
        background: #4545C6;
        color:#FFFFFf;
        font-weight: normal;
        border-radius: 6px;
        width: 50%;
        order:2;
        margin-bottom: 1rem;
        font-size: 0.6rem;
    }
    .AccountsTable{
        width: 90%;
        margin: auto;
        margin-top: 2%;
    }
    .AccountsTable > tr{
        text-align: center;
    }
    .AccountsTable >tr>th{
        padding: 0.5rem 0rem;
        font-size: 0.6rem;
        font-weight: normal;
        text-align: center;
        background: #cccccc;
        width: 25%;
    }
    .AccountsTable > tr > td{
        padding: 1rem 0rem;
        font-size: 0.6rem;
        text-align: center;
    }
    .AccountsTable > tr > td > button{
        padding: 0;
        border-radius: 0.5em;
        font-size: 0.5rem;
        border: 0px;
        margin: auto;
        justify-content: center;
        display: flex;
        background: #E75151;
        color: white;
        align-items: center;
    }
    .AccountsTable > tr > td:nth-child(3) {
        
        gap: 0.4rem;
        width: 60%;
        margin: auto;
        padding-top: 1.2rem;
        display: flex;
        align-items: center;
    }
    .ActiveLink{
        background-color: #4545C6 !important;
        color: white !important;
        
    }
    .ActiveLinkDesigner{
        font-weight: bolder;
        color:#4545C6
    }
    /* Manage Social Accounts */
    .ManageSocialAccountsHeader
    {
        text-align: center;
        font-weight: normal;
        font-size: 1.1rem;
        margin: 3rem 0px;
    }
    .UserHeader{
        display: flex;
        justify-content: space-between;
        width: 90%;
        height: 13vh;
        margin: auto;
        margin-bottom: 6%;
        align-items: flex-end;
    }
    .UserSelector{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    .UserSelector > span{
        font-size: 1rem;
    }
    .UserSelector > select{
        
        padding: 0.3rem 0.2rem;
        font-weight: normal;
        width: 100%;
        font-size: 0.8rem;
        outline: #4545C6;
    }
    .UserSelector > select >option{
        font-weight: normal;
        border: 3px solid black;
        color: black;
        
    }
    .CreatePostbtn{
        margin-top: 3.5vh;
        width: 36%;
        height: 46%;
        color: #FFFFFF;
        font-size: 0.8rem;
        background: #4545C6;
    }
    .ViewPostSection{
        width: 60vw;
        margin: auto;
        margin-top: 10%;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        grid-column-gap: 1rem;
        margin-bottom: 10%;
    }
    .ViewPostSection > img{
        width: 100%;
        height: 20pc;
        border: 1px solid black;
    }
    .ImgSection{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
    }
    /* Show Image */
    .ShowImgSection{
        width: 90%;
        margin: auto;
        margin-top: 3%;
    }
    .ShowImgSection > button{
        
        border:none;
        border-radius: 10px;
        font-size: 0.9rem;
        width: 0%;
        
    }
    .ImgCommentSection{
        display: grid;
        grid-template-columns: 2fr;
        grid-gap: 1rem;
    
    }
    .ImgSection > img{
        height: 100%;
        width: 100%;
        margin: 10% 0px;
       
    }
    .CommentSection{
        border-radius: 5px;
        max-height: 86%;
        width: 90%;
        margin: 20% auto;
        
    }
    .CommentHeader{
        display: flex;
        flex-direction: column;
        margin: 0rem 0px;
        padding: 1rem 0;
    }
    .CommentHeader > h1{
        font-size: 1.6rem;
        padding-top: 1rem;
        
    }
    .CommentHeader > span{
        font-size: 0.8rem;
    }
    .Comment{
        padding: 1rem 2rem;
        display: grid;
        height: 67%;
        grid-template-columns: 1fr 6fr;
        grid-template-rows: repeat(6,2.4rem 1.3rem);
        overflow: auto;
        grid-row-gap: 1rem;
        row-gap: 1rem;
        
    }
    .UserCommentSection{
        padding: 1rem 1rem;
        width: 100%;
        display: flex;
        margin: 0px 0px;
        /* grid-template-columns: 1fr 6fr 1fr;
        grid-template-rows: 1fr; */
    }
    .UserCommentSection > button{
        font-size: 0.7rem;
        border: none;
        padding: 0px;
        width: 23%;
    }
    .Comment > img{
        width: 50px;
        height: 50px;
        border: 1px solid #cccccc;
        border-radius: 50%;
    }
    .UserPic{
        width: 50px;
        height: 50px;
        border: 5px solid #cccccc;
        border-radius: 50%;
    }
    .Comment > p{
        border: none;
        border-radius: 10px;
        margin-left: 1rem;
        font-size: 0.6rem;
        background: #E9E8FF;
        height: fit-content;
        width: 80%;
        max-width: 14rem;
    }
    .UserComment{
        
        border-radius: 10px;
        margin-left: 1rem;
        width: 90%;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 0.6rem;
        text-align: left;
        background: #EEEEEE;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
    .UserCommentEmoji{
        display: flex;
        gap: 0.4rem;
        position: absolute;
        bottom: 22%;
        right: 32%;
        background: none;
        height: 16%;
    }
    .Comment > span{
        grid-column: 2/3;
        grid-row: 2/3;
        text-align: left;
        margin-left: 1rem;
    }
    .TimeLikeReply{
        grid-column: 2/3;
        display: flex;
        
    }
    .Time{
        margin-left: 2rem;
        font-size: 0.5rem;
    }
    .LikeReply{
        margin-left: 2rem;
        font-size: 0.5rem;
        width: 8vh;
    }
    .CreatePostSection{
        /* display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 100px 4fr; */
    }
    .CreatePostSection > button{
       
        background: #FFFFFF;
        border-radius: 10px;
        font-size: 0.9rem;
        margin: 0 1rem;
        margin-top: 1rem;
        width: 0%;
        
        
    }
    .PostSection{
        grid-column: 1/4;
        display: flex;
        width: 90%;
        height: 45vh;
        margin: auto;
        margin-top: 0%;
        margin-left: 0%;
    }
    .CreatePostHeadings {
        display: flex;
        flex-direction: column;
        
    }
    .CreatePostHeadings > p{
        margin-top: 1.5rem;
        margin-bottom: 0rem;
    }
    .PostSection{
        width: 100%;
    }
    .PostSection > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: auto;
        width: 75%;
    }
    .PostSection > div > div > h3{
        font-size: 1.4rem;
    }
    .PostSection > div > div > p{
        font-size: 0.6rem;
    }
    .PostSection > div > p{
        font-size: 0.6rem;
    }
    
    #CreatePost{
        margin-top: 6%;
        margin-left: 0%;
        width: 100%;
        grid-column: 1/3;
        /* height: 15vh; */
        border-radius: 5px;
        padding: 1vh 1rem 8vh;
        font-size: 0.6rem;
        margin-bottom: 6%;
        border: 1px solid #5A54E5;
    }
    .PostBtnsection{
        display: flex;
        justify-content: space-between;
        padding: 0rem;
        width: 100%;
    }
    .PhotoVideo{
        background: #FFFFFF;
        box-shadow: 0px 4px 22px rgb(0 0 0 / 7%);
        border: 1px solid #5A54E5;
        padding: 0.5rem 0.2rem;
        width: 44%;
        display: flex;
        font-size: 0.56rem;
        grid-gap: 0.7rem;
        gap: 0.7rem;
        align-items: center;
        font-weight: bolder;
        border-radius: 5px;
    }
    .PostBtnsection > div{
        width: 50%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
    }
    .Postbtn,.CancelBtn{
        border: 1px solid #5A54E5;
        height: 90%;
        border-radius: 5px;
        font-size: 0.56rem;
        width: 42%;
    }
    .Postbtn {
        color:white;
        background: #4545C6;
        border-radius: 5px;
        border:none;
    }
    .CancelBtn{
        background-color: white;
        color:#4545c6;
    }
    .PhotoVideo > img{
        height: 1rem;
        width: 1.2rem;
    
    }
    /* Add user section */
    
    .AddNewAccountContainer{
        width: 90%;
        margin: 5% auto;
    }
    .AddNewAccountBanner {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
    
    }
    .AddNewAccountBanner > button{
        padding: 0rem 0rem;
        background: #FFFFFF;
        border-radius: 10px;
        font-size: 0.9rem;
        width: 30%;
        height: 100%;
        margin :0px auto;
        margin-top: 10px;
    }
    .PlusAddNewAccount > h1{
        text-align: start;
        font-size: 1.5rem;
        margin: 0px auto;
    }
    .PlusAddNewAccount { 
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        margin: 2rem auto 1rem;
        width: 99%;

    }
    .PlusAddNewAccount > img{
        height: 1.3rem;
        width: 1.3rem;
    }
    .AddNewAccountHeader > span{
        font-size: 0.67rem;
        margin: auto;
        text-align: center;
    }
    .AccountTypes{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 15% auto;
    }
    .AccountTypes > button{
        margin: 5% auto;
        width: 100%;
        
        padding: 0.8rem 0rem;
        font-weight: normal;
        background: white;
        border-radius: 5px;
    }
    .AccountTypes > button > img{
        height: 1.3rem;
        width: 1.3rem;
    }

    /* Design Editor */
    .DesignMaker{
        display: flex;
        position: absolute;
        right: 0%;
        z-index: 1;
        background-color: #fff;
        border: 1px solid black;
        border-radius: 5px;
        align-items: center;
        top: 11vh;
        flex-direction: column;
        width: 36%;
        margin-left: auto;
        font-size: 0.61rem;
        display: none !important;

    
    }
    .DesignMaker > a{
        color: black; 
        margin: 20px 0px; 
        text-align: start;
    }
    .DesignEditorHeader{
        text-align: center;
        margin-top: 5vh;
        font-size: 1.7rem;
        font-weight: normal;
    }
    .DesignerCreateNew{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5rem auto 2rem;
    }
    .DesignerCreateNew > span{
        font-size: 1.1rem;
    }
    .DesignerCreateNew > button{
        background: #4545C6;
        color: #FFFFFf;
        padding: 0.6em 0em;
        width: 10em;
        font-weight: normal;
        border-radius: 6px;
        font-size: 0.8rem;
    }
    .DesignerTemplates{
        border: 1px solid;
        margin-top: 4rem;
    }
    .DesignerTemplates > img{
        width: 94%;
        height: calc(30vh + 30vw);
    }
    .DesignEditorCanvasArea{
        position: relative;
        display: flex;
        width: 90vw;
        height: 200vh;
        flex-direction: column;
        left: 4vw;
        align-items: center;
        
        top: 7vh;
        background: #FFFFFF;
        margin-bottom: 17vh;
        box-shadow: 0px 4px 14px 6px rgb(0 0 0 / 6%);
    
    }
    .DesignEditorBanner{
        
        width: 90%;
        
        margin: 10% auto 2%;
        display: flex;
        justify-content: space-between;
    }
    .DesignEditorBanner  > button{
        
        width: 0%;
        height: 12vw;
        padding: 0.1rem 0rem;
        font-weight: bolder;
        background: white;
        font-size: 0rem;
        border-radius: 10px;
        
    }
    .DesignEditorBanner>button>img {
        height: 1rem;
        width: 1.5rem;
    }
    .DesignEditorBanner > div{
        
        width: 90%;
        display: flex;
        justify-content: space-around;
        
    }
    .DesignEditorBanner > div > button{
        width: 50%;
        margin: 0px 10px;
        padding: 0.1rem 0rem;
        font-weight: normal;
        background: #4545C6;
        border: 3px solid #4545C6;
        border-radius: 5px;
        color: white;
        font-size: 0.6rem;
    }
    .Designer{
        margin-top: 5%;
        width: 100%;
        display: grid;
        height: 30vh;
        grid-template-columns: 1fr;
    }
    
    .Designer > canvas{
        border: 1px solid;
        height: 90vh;
        background-color: #C4C4C4;
        width: 90vw;
        margin-top: 10%;
    }
    .DesignerButtons{
        border: 1px solid #C3C5F5;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        height: 100%;
        margin-left: 2%;
        width: 95%;
    }
    .DesignerButtons > button{
        height: 43%;
        width: 87%;
        padding: 0.1rem 0px;
        margin-left: 10%;
        margin-top: 2rem;
        background: #4545C6;
        color: #FFFFFF;
        font-size: 1rem;
        border-radius: 4px;
        border: 3px solid #4545C6;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .DesignerButtons > button:nth-child(1){
        padding-top: 1rem;
    }
    .DesignerButtons > button:nth-child(2){
        padding-top: 1rem;
    }
    .DesignerButtons > button:nth-child(3){
        padding:0.4rem;
    }
    .DesignerButtons > button:nth-child(4){
        padding-top: 0.6rem;
    }
    .DesignerButtons > button:nth-child(5){
        padding-top: 0.6rem;
    }
    .DesignerButtons > button:nth-child(6){
        padding-top: 0.7rem;
    }
    .DesignerButtons > button:nth-child(7){
        padding-top: 0.8rem;
    }
    .DesignerButtons > button:nth-child(8){
        padding-top: 0.6rem;
    }
    .DesignerButtons > button:nth-child(9){
        
    padding-top: 0.9rem;

    }
    .DesignerButtons > button:nth-child(10){
        padding-top: 0rem;
    }
    .DesignerButtons > button:nth-child(11){
        padding-top: 0rem;
    }
    .DesignerButtons > button:nth-child(12){
        padding-top: 0rem;
    }
    .DesignerButtons > button:nth-child(13){
        padding-top: 0.5rem;
    }
    .DesignerButtons > button:nth-child(14){
        padding-top: 0.8rem;
    }
    .DesignerButtons > button > img{
        width: 100%;
        height: 100%;
    }
    svg {
        width: 120%;
        height: 100%;
    }
    .AccountTypes > button > svg{
        width: 5vw;
        height: 3vh;
    }
    .ShowSavedDesigns {
        flex-direction: column;
        margin: auto;
        width: 100%;
        height: 200%;
    }
    .ShowSavedDesigns > div > img {
        box-shadow: 0px 4px 20px #575AC2;
        width: 100%;
        height: 555px;
    }
    .ShowSavedDesigns > div:nth-child(1){
        width: 90%;
        margin-top: 5rem;

    }
    .ShowSavedDesigns > div:nth-child(2){
        width: 70%;
        flex-direction: column;
        

    }
    .ShowSavedDesigns > div:nth-child(2) > button{
        margin-top: 0rem;
        width: 100%;
        padding: 1em 1.1em;
    }
    .ShowSavedDesigns > div:nth-child(2) > button:nth-child(1) {
        margin-top: 3rem;
        
    }
    .ShowSavedDesigns > div:nth-child(2) > button:nth-child(3){
        margin-bottom: 3rem;
    }
    
}
@media only screen and (max-width: 400px){
    .ShowSavedDesigns > div:nth-child(1) {
        width: 90%;
        margin-top: 11rem;
        height: 100%;
    }
}