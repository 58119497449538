html {
  scroll-behavior: smooth;
}
.navbar {
  height: 5rem;
  color: #4545c6;
}
.H_Menu {
  visibility: hidden;
  display: none;
}
.Logo {
  font-size: 1.5rem;
  font-weight: bolder;
}
.header {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.nav {
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 9rem;
  font-size: 1.2;
  visibility: visible !important;
  transform: translateY("0%");
}
.nav > li > a {
  color: #4545c6;
  font-weight: bolder;
  text-decoration: none;
}
.footer {
  height: 5rem;
  bottom: 0%;
  background: #4545c6;
  box-shadow: 0px -9px 25px rgb(0 0 0 / 25%);
}
.footerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  height: 100%;
}
.FollowUs {
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.3rem;
  color: white;
}
.icons {
  width: 38%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  gap: 1rem;
}
.Img {
  width: 50%;
  height: 654px;
}
img {
  width: 100%;
  height: 100%;
}
.HeroSection {
  display: flex;
  width: 80%;
  margin: 5rem auto;
  justify-content: center;
}
.Introduction {
  width: 50%;
  margin: 13% auto;
}
.Introduction > h1 {
  font-size: 2.2rem;
  font-weight: bolder;
  color: #4545c6;
}
.Introduction > h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 4rem 0px 5rem;
}
.IntroductionBtns {
  display: flex;
  gap: 1rem;
}
.Infobtn {
  background: #ffffff;
  border: 2px solid #4545c6;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 15px 7px rgb(0 0 0 / 25%); */
  font-weight: 800;
  font-family: "Poppins";
  border-radius: 7px;
  width: 11rem;
  color: #4545c6;
  font-size: 0.9rem;
  padding: 1em 0em;
  /* height: 50px; */
}
.Googlebtn {
  background: #4545c6;
  border: none;
  box-sizing: border-box;
  font-weight: 800;
  /* box-shadow: 0px 4px 15px 7px rgba(0, 0, 0, 0.25); */
  border-radius: 7px;
  font-family: "poppins";
  color: #ffffff;
  font-size: 0.9rem;
  width: 13rem;
  padding: 1em 0em;
}
.Services {
  height: fit-content;
}
.Services > h1 {
  margin-bottom: 7rem;
  font-weight: bolder;
}
.banner {
  background-color: #4545c6;
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BannerImg {
  width: 50%;
  height: 100%;
}
.BannerDetails {
  width: 50%;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.BannerDetails > h1 {
  font-size: 2.3rem;
  margin: 3.2rem 7rem;
  font-weight: bolder;
}
.BannerDetails > p {
  margin: -2rem 0rem 1rem 7rem;
  text-align: start;
  width: clamp(35ch, 45ch, 50ch);
  font-size: 1rem;
}
.Services > h1 {
  text-align: center;
  font-size: 2.5rem;
}
.DesignEditor {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DesignEditor > img {
  height: 64%;
  width: 50%;
}
.DesignEditor > div > h1 {
  font-size: 2rem;
  font-weight: bolder;
  margin: 25px 0%;
  width: 26ch;
}
.DesignEditor > div > p {
  font-size: 1rem;
  width: 50ch;
}
.Designbtn {
  width: 10rem;
  height: 52px;
  color: #ffffff;
  font-family: "poppins";
  font-size: 1rem;
  margin: 3rem auto 0rem;
  background: #4545c6;
  border-radius: 7px;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .Img {
    width: 53%;
    height: 585px;
    margin-top: 3rem;
  }
  .HeroSection {
    width: 85%;
  }
  .Introduction > h1 {
    font-size: 2.2rem;
    font-weight: bolder;
  }
  .Introduction > h3 {
    font-size: 1.2rem;
    font-weight: normal;
    margin: 4rem 0px 5rem;
  }
  .IntroductionBtns {
    display: flex;
  }
  .Infobtn {
    background: #ffffff;
    border: 2px solid #4545c6;
    box-sizing: border-box;
    color: #4545c6;
    font-weight: 800;
    font-size: 90%;
    font-family: "Poppins";
    border-radius: 7px;
    width: 40%;
    height: 50px;
  }
  .Googlebtn {
    background: #4545c6;

    box-sizing: border-box;

    border-radius: 7px;
    font-family: "poppins";
    color: #ffffff;
    font-size: 71%;
    width: 40%;
    height: 50px;
    margin-left: 0rem;
  }
  .Googlebtn > i {
    font-size: 0.7rem !important;
  }
  .banner {
    background-color: #4545c6;
    max-height: 78vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .BannerDetails > h1 {
    font-size: 1.7rem;
    margin: 6rem 4rem !important;
  }
  .BannerDetails > p {
    margin: -2rem 0rem 1rem 4.5rem;
    text-align: start;
    width: 78%;
    font-size: 1.2rem;
  }

  .DesignEditor > h1 {
    font-size: 2rem;
    font-weight: bolder;
    margin: 0px 29%;
    text-align: center;
  }
  .Designbtn {
    width: 20%;
    height: 64px;
    color: #ffffff;
    font-family: "poppins";
    font-size: 1.2rem;
    margin: 3rem auto 0rem;
    background: #4545c6;
    border-radius: 7px;
  }
  .FollowUs {
    width: 25vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.3rem;
    color: white;
  }
  .icons {
    width: 38%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
  .DesignEditor {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  }
  .DesignEditor > img {
    height: 54%;
    width: 40%;
  }
  .DesignEditor > div {
    text-align: center;
  }
  .DesignEditor > div > h1 {
    font-size: 1.6rem;
    font-weight: bolder;
    margin: 25px auto;
    width: 100%;
  }
  .DesignEditor > div > p {
    font-size: 0.9rem;
    width: 83%;
    margin: auto;
  }
  .Designbtn {
    width: 8rem;
    height: 44px;
    color: #ffffff;
    font-family: "poppins";
    font-size: 0.9rem;
    margin: 2rem auto 0rem;
    background: #4545c6;
    border-radius: 7px;
    border: none;
  }
}
@media only screen and (max-width: 768px) {
  .Img {
    width: 50%;
    height: 600px;
    margin-top: 1rem;
  }
  .Introduction > h1 {
    font-size: 2rem;
    font-weight: bolder;
  }
  .Introduction > h3 {
    font-size: 1rem;
    font-weight: normal;
    margin: 4rem 0px 5rem;
  }
  .Infobtn {
    background: #ffffff;
    box-sizing: border-box;
    border: 2px solid #4545c6;

    font-weight: 800;
    font-family: "Poppins";
    border-radius: 7px;
    width: 40%;
    font-size: 0.6rem;
    height: 50px;
  }
  .Googlebtn {
    background: #4545c6;

    border-radius: 7px;
    font-family: "poppins";
    color: #ffffff;
    font-size: 0.6rem;
    width: 50%;
    height: 50px;
    margin-left: 1rem;
  }
  .Googlebtn > i {
    font-size: 0.7rem !important;
  }
  .Services > h1 {
    margin-bottom: 7rem;
    font-size: 2.4rem;
  }
  .banner {
    background-color: #4545c6;
    max-height: 50vh;
    min-height: 38vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .BannerDetails {
    width: 50%;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .BannerDetails > h1 {
    font-size: 1.1rem;
    /* margin: 4rem 3rem 6rem 3rem !important; */
    margin: 6rem 2rem 6rem 3rem !important;
  }
  .BannerDetails > p {
    /* margin: -2rem 0rem 1rem 0rem; */
    margin: 0rem -2rem 0rem 3.5rem;
    text-align: start;
    width: 78%;
    font-size: 1rem;
  }
  .Banner2Details {
    margin: -2rem 0rem 1rem 3rem !important;
    text-align: start;
    width: 73%;
    font-size: 1rem;
  }
  .DesignEditor {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
  }
  .DesignEditor > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .DesignEditor > div > p {
    font-size: 0.8rem;
    width: 90%;
  }
  .DesignEditor > div > h1 {
    font-size: 1.2rem;
    margin: 13px 0%;
  }

  .Designbtn {
    width: 22%;
    height: 2.2rem;
    color: #ffffff;
    font-family: "poppins";
    font-size: 0.8rem;
    margin: 1.4rem auto 0rem;
    background: #4545c6;
    border-radius: 7px;
  }
  .FollowUs {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.3rem;
    color: white;
  }
  .icons {
    width: 38%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
}
@media only screen and (max-width: 540px) {
  .navbar {
    height: 5rem;
    box-shadow: 0px 4px 30px 1px rgba(0, 0, 0, 0.25);
  }
  .Logo {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .header {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  .nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 11vh;
    left: 0%;
    padding: 1rem;
    margin: -6px 0rem;
    background: #f7f7f7;
    list-style: none;
    justify-content: space-between;
    width: 92vw;
    transition: 0.5s ease transform;
    visibility: hidden !important;
    z-index: 1;
    transform: translateY(-20%) !important;
  }
  .nav > li {
    padding: 1rem;
  }
  .nav > li > a {
    text-decoration: none;
  }
  .H_Menu {
    visibility: visible;
    display: block;
  }
  .footer {
    height: 5rem;
    bottom: 0%;
    background: #4545c6;
    box-shadow: 0px -9px 25px rgb(0 0 0 / 25%);
  }
  .footerBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    height: 100%;
  }
  .FollowUs {
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.8rem;
    color: white;
  }
  .icons {
    width: 45%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    gap: 0.2rem;
  }
  .Img {
    width: 100%;
    height: 654px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .HeroSection {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 4rem auto 0rem;
    justify-content: center;
    align-items: center;
  }
  .Introduction {
    width: 100%;
    margin: 6% auto;
  }
  .Introduction > h1 {
    font-size: 1.9rem;
    font-weight: bolder;
  }
  .Introduction > h3 {
    font-size: 1rem;
    font-weight: normal;
    margin: 3rem 0px 3rem;
  }
  .IntroductionBtns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Infobtn {
    background: #ffffff;
    box-sizing: border-box;
    border: 2px solid #4545c6;

    font-weight: 800;
    font-family: "Poppins";
    border-radius: 7px;
    width: 10rem;
    margin: 0px 21%;
    height: 50px;
  }
  .Googlebtn {
    background: #4545c6;

    box-sizing: border-box;

    border-radius: 7px;
    font-family: "poppins";
    color: #ffffff;
    width: 10rem;
    height: 50px;
    margin: 0px 21%;
    margin-top: 10%;
  }
  .Services {
    height: fit-content;
  }
  .Services > h1 {
    margin-bottom: 9rem;
  }
  .banner {
    background-color: #4545c6;
    max-height: 150vh;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .BannerImg {
    width: 100%;
    height: 100%;
  }
  .BannerDetails {
    width: 80%;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .BannerDetails > h1 {
    font-size: 1.5rem;
    margin: 4rem 0rem !important;
  }
  .BannerDetails > p {
    margin: -2rem 0rem 10rem 0rem;
    text-align: start;
    width: 100%;
    font-size: 0.8rem;
  }
  .Banner2Details {
    margin: -2rem 0rem 10rem 0rem !important;
    text-align: start;
    width: 73%;
    font-size: 1rem;
  }
  .Banner2Detail {
    order: 2;
  }
  .Banner2Img {
    order: 1;
  }
  .Services > h1 {
    text-align: center;
    font-size: 1.5rem;
  }
  .DesignEditor {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .DesignEditor > div > h1 {
    font-size: 1.2rem;
    font-weight: bolder;
    margin: 10px 1%;
    text-align: center;
  }
  .DesignEditor > div > p {
    text-align: center;
    font-size: 0.6rem;
    width: 50ch;
  }
  .Designbtn {
    width: 7rem;
    height: 2.4rem;
    color: #ffffff;
    font-family: "poppins";
    font-size: 0.8rem;
    margin: 2rem auto 3rem;
    background: #4545c6;
    box-shadow: 0px 4px 15px 7px rgb(0 0 0 / 25%);
    border-radius: 7px;
  }
}
@media only screen and (max-width: 301px) {
  .Logo {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .H_Menu {
    font-size: 1.3rem !important;
  }
  .Introduction > h1 {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .Introduction > h3 {
    font-size: 0.8rem;
    font-weight: normal;
    margin: 3rem 0px 3rem;
  }
  .BannerDetails > h1 {
    font-size: 1.2rem;
    margin: 4rem 0rem !important;
  }
  .BannerDetails > p {
    margin: -2rem 0rem 10rem 0rem;
    text-align: start;
    width: 100%;
    font-size: 0.7rem;
  }
  .DesignEditor > img {
    height: 41%;
    width: 40%;
  }
  .DesignEditor > div > h1 {
    font-size: 0.8rem;
    font-weight: bolder;
    margin: 10px 3%;
    text-align: center;
  }
  .DesignEditor > div > p {
    text-align: center;
    font-size: 0.567rem;
    width: 50ch;
  }
  .Designbtn {
    width: 8em;
    height: 3.4em;
    color: #ffffff;
    font-family: "poppins";
    font-size: 0.6rem;
    margin: 2rem auto 3rem;
    background: #4545c6;
    border-radius: 7px;
  }
  .FollowUs {
    width: 40vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.8rem;
    color: white;
    gap: 0.4rem;
  }
}
.tos_content ol li {
  list-style-position: outside;
  padding: 20px;
}
